import React, { useState } from 'react';
import styles from './composition.module.scss';
import plusIcon from '../../images/sopk/+.svg';
import minusIcon from '../../images/sopk/_.svg';
import supplementBoxImage from '../../images/sopk/pill_E.svg';
import supplementBoxImageSplus from '../../images/sopk/pill_S_plus.svg';
import Doctor from '../../images/sopk/doctor2.svg';
import Alexandra from '../../images/sopk/Coach_Alexandra_BM.webp';
import Eric from '../../images/sopk/eric_remini.jpg';
import Quotes from '../../images/sopk/quote.png';

const Composition = () => {
  const [isOpenPosologie, setIsOpenPosologie] = useState(false);
  const [isOpenCompositions, setIsOpenCompositions] = useState(false);
  const [isOpenInformation, setIsOpenInformation] = useState(false);

  const toggleDetailsPosologie = () => {
    setIsOpenPosologie(!isOpenPosologie);
  };

  const toggleDetailsCompositions = () => {
    setIsOpenCompositions(!isOpenCompositions);
  };

  const toggleDetailsInformation = () => {
    setIsOpenInformation(!isOpenInformation);
  };

  return (
    <div className={styles.compositionContainer}>
      <div className={styles.compositionsection}>
        <div className={styles.compositionsectionHeader} onClick={toggleDetailsPosologie}>
          Posologie
          <img src={isOpenPosologie ? minusIcon : plusIcon} alt={isOpenPosologie ? 'Moins' : 'Plus'} className={styles.compositionicon} />
        </div>
        <div className={`${styles.compositiondetails} ${isOpenPosologie ? styles.open : ''}`}>
          <p><b>Essentiel</b>: Prendre 2 gélules par jour.</p>
          <p><b>Omega-3</b>: Prendre 2 gélules par jour au cours d'un repas.</p>
          <p><b>S+</b>: Prendre 4 gélules par jour.</p>
        </div>
      </div>

      <div className={styles.compositionsection}>
        <div className={styles.compositionsectionHeader} onClick={toggleDetailsCompositions}>
          Compositions
          <img src={isOpenCompositions ? minusIcon : plusIcon} alt={isOpenCompositions ? 'Moins' : 'Plus'} className={styles.compositionicon} />
        </div>
        <div className={`${styles.compositiondetails} ${isOpenCompositions ? styles.open : ''}`}>
            <div className={styles.container_flex}>
                <div className={styles.container_composition}>
                    <img src={supplementBoxImage} alt="Boîte de complément alimentaire" className={styles.supplementBox} />
                    <p><b>E+ :</b> Complément alimentaire à base de vitamines et minéraux.</p>
                    <p className={styles.underline}>APPORT POUR 2 GÉLULES :</p>
                    <p><b>Vitamine E</b> : 12 mg</p>
                    <p><b>Zinc</b> : 10 mg</p>
                    <p><b>Vitamine B6</b> : 1,4 mg</p>
                    <p><b>Acide folique (Vitamine B9)</b> : 250 µg</p>
                    <p><b>Iode</b> : 150 µg</p>
                    <p><b>Sélénium</b> : 75 µg</p>
                    <p><b>Vitamine D3</b> : 10 µg</p>
                    <p><b>Vitamine B12</b> : 5 µg</p>
                </div>

                <div className={styles.container_composition}>
                    <img src={supplementBoxImage} alt="Boîte de complément alimentaire" className={styles.supplementBox} />
                    <p><b>E omega 3 :</b> Complément alimentaire d'apport en oméga-3.</p>
                    <p className={styles.underline}>APPORT POUR 2 GÉLULES :</p>
                    <p><b>Huile de poisson</b> : 1000 mg</p>
                    <p><b>Total acides gras oméga-3, dont</b> : 800 mg</p>
                    <p><b>EPA (Acide eicosapentaénoïque)</b> : 400 mg</p>
                    <p><b>DHA (Acide docosahexaénoïque)</b> : 300 mg</p>
                </div>
            </div>

            <div className={styles.container_infoBox}>
                <img src={Doctor} alt="Icône Doctor" className={styles.compositionicon} />
                <div className={styles.infoBox}>
                    <p><b>Vitamine B6 :</b> La vitamine B6 contribue à la régularisation de l'activité hormonale.</p>
                    <p><b>Zinc :</b> Le zinc contribue à une fertilité et une reproduction normale.</p>
                </div>
            </div>

            <div className={styles.container_composition}>
                <img src={supplementBoxImageSplus} alt="Boîte de complément alimentaire" className={styles.supplementBox} />
                <p><b>S+ :</b> Complément alimentaire à base d'inisotol, coenzyme Q10, vitamines et minéraux.</p>
                <p className={styles.underline}>APPORT POUR 4 GÉLULES :</p>
                <p><b>Myo-inositol</b> : 1950 mg</p>
                <p><b>D-chiro-inositol</b> : 50 mg</p>
                <p><b>Total inositol</b> : 2000 mg</p>
                <p><b>Coenzyme Q10</b> : 50 mg</p>
                <p><b>Chrome</b> : 120 µg</p>
                <p><b>Acide folique (Vitamine B9)</b> : 50 µg</p>
                <p><b>Vitamine D3</b> : 10 µg</p>
            </div>

            <div className={styles.container_infoBox}>
                <img src={Doctor} alt="Icône Doctor" className={styles.compositionicon} />
                <div className={styles.infoBox}>
                    <p><b>Chrome :</b> Le chrome participe à améliorer la composition corporelle.</p>
                    <p><b>Inositol :</b> L'inositol contribue à restaurer l'ovulation spontanée.</p>
                </div>
            </div>
        </div>
      </div>

      <div className={styles.compositionsection}>
        <div className={styles.compositionsectionHeader} onClick={toggleDetailsInformation}>
          En savoir plus
          <img src={isOpenInformation ? minusIcon : plusIcon} alt={isOpenInformation ? 'Moins' : 'Plus'} className={styles.compositionicon} />
        </div>
        <div className={`${styles.compositiondetails} ${isOpenInformation ? styles.open : ''}`}>
            <div className={styles.container_info}>
                <img src={Alexandra} alt="Alexandra" className={styles.compositionicon} />
                <div className={styles.info}>
                    <img src={Quotes} alt="double quotes"/>
                    <p>Les compléments <b>E+</b> et <b>E omega 3</b> du pack Essentiel apportent <b>toutes les vitamines et oligo-éléments</b> que les médecins considèrent comme <b>indispensables à la fertilité</b>, dont les vitamines B6, B9, B12, D et E, le zinc et le sélénium. La vitamine B9 (folates), particulièrement importante, est apportée directement sous sa forme active et est associée à de la vitamine B12, connue pour renforcer son activité sur la fertilité.</p>
                    <p className={styles.info_name}><b>Alexandra Murcier</b></p>
                    <i>Diététicienne-nutritionniste spécialisée dans les approches comportementales de l'alimentation.</i>
                </div>
            </div>
            <div className={styles.container_info}>
                <img src={Eric} alt="Alexandra" className={styles.compositionicon} />
                <div className={styles.info}>
                    <img src={Quotes} alt="double quotes"/>
                    <p>L'association de chrome et d'inositols a des effets bénéfiques démontrés en clinique tant sur le poids que sur le contrôle de la glycémie.</p>
                    <p className={styles.info_name}><b>Éric Lameignère</b></p>
                    <i>Docteur en sciences.</i>
                </div>
            </div>
        </div>
      </div>
      
    </div>
  );
};

export default Composition;