import { CURRENT_CART_VERSION, defaultCart, products, programs } from '../context/CartContext';
import useLocalStorage from '../hooks/useLocalStorage';

/**
 * @typedef {import('../context/CartContext').ProgramName} ProgramName
 * @typedef {import('../context/CartContext').ProductName} ProductName
 * @typedef {import('../context/CartContext').StripeCoupon} StripeCoupon
 * @typedef {import('../context/CartContext').Cart} Cart
 */

/**
 * Hook to access the current cart and update its items
 * @returns {[Cart, Function]}
 */
export function useCart() {
  /** @type {[Cart, Function]} */
  const [storedCart, setStoredCart] = useLocalStorage('cart', defaultCart);

  if (storedCart.version !== CURRENT_CART_VERSION) {
    // A cart structure upgrade has been deployed. Reset the cart if so.
    setStoredCart(defaultCart);
  }

  /**
   * Helper for managing cart state : add and remove products, programs, coupons...
   * @param {'add'|'remove'|'reset'} action - operation to perform
   * @param {'program'|'product'|'coupon'} type - object to perform the operation on
   * @param {ProgramName|ProductName|StripeCoupon} value - specific payload: either a product id, a program id, a Stripe coupon, or undefined (eg to remove something)
   * @returns {Void}
   */
  function updateCart(action, type, value) {
    if (
      (type === 'program' && !Object.values(programs).includes(value)) ||
      (type === 'product' && !Object.values(products).includes(value))
    ) {
      throw new Error(`invalid ${type} identifier: ${value}`);
    }

    const newCart = { ...storedCart };
    switch (type) {
      case 'coupon':
        if (action === 'remove') {
          newCart.coupon = null;
        }
        if (action === 'add') {
          newCart.coupon = value;
        }
        break;

      case 'product':
        if (action === 'add') {
          if (
            value !== products.ESSENTIEL_SUPPLEMENTS &&
            !newCart.contents.items.map((i) => i.id).includes(products.ESSENTIEL_SUPPLEMENTS)
          ) {
            // we want, when adding a product, to also add the ESSENTIEL_SUPPLEMENTS product if missing.
            newCart.contents.items.push({ ...newCart.definition.availableProducts[products.ESSENTIEL_SUPPLEMENTS] });
          }

          if (
            value !== products.ESSENTIEL_COACHING &&
            !newCart.contents.items.map((i) => i.id).includes(products.ESSENTIEL_COACHING)
          ) {
            // we want, when adding a product, to also add the ESSENTIEL_COACHING product if missing.
            newCart.contents.items.push({ ...newCart.definition.availableProducts[products.ESSENTIEL_COACHING] });
          }

          if (
            value !== products.ESSENTIEL_MENUS &&
            !newCart.contents.items.map((i) => i.id).includes(products.ESSENTIEL_MENUS)
          ) {
            // we want, when adding a product, to also add the ESSENTIEL_MENUS product if missing.
            newCart.contents.items.push({ ...newCart.definition.availableProducts[products.ESSENTIEL_MENUS] });
          }

          if (newCart.contents.items.some((product) => product.id === value)) {
            // we don't want more than 1 instance of each product in the cart.
            return;
          }
          newCart.contents.items.push({ ...newCart.definition.availableProducts[value] });
        }
        if (action === 'remove') {
          newCart.contents.items = storedCart.contents.items.filter((product) => product.id !== value);
        }
        break;

      case 'program':
        if (action === 'add') {
          newCart.contents.items = [];
          newCart.contents.program = value;
          for (const product of newCart.definition.availablePrograms[value].products) {
            newCart.contents.items.push({ ...newCart.definition.availableProducts[product] });
          }
        }

        break;
      default:
        if (action === 'reset') {
          return setStoredCart(defaultCart);
        }
        throw new Error(`Invalid type: ${type}`);
    }

    // COMPUTE DISCOUNT
    if (!newCart.coupon) {
      newCart.discountAmount = null;
      newCart.discountPercent = null;
      for (const item of newCart.contents.items) {
        item.discountedPrice = null;
      }
    } else {
      const coupon = newCart.coupon.coupon;

      if (coupon.applies_to && coupon.applies_to.products && coupon.applies_to.products.length > 0) {
        // We know the coupon applies only to a subset of the cart

        // --> remove previously set global discount
        newCart.discountAmount = null;
        newCart.discountPercent = null;

        for (const item of newCart.contents.items) {
          const couponProductsIds = coupon.applies_to.products;
          const isCouponValidForCurrentItem = couponProductsIds.includes(item.stripe_product_id);

          if (isCouponValidForCurrentItem) {
            // compute discounted price for current cart item
            const percentOff = coupon.percent_off;
            const amountOff = coupon.amount_off / 100;
            let discountedPrice = item.price;
            if (!!percentOff) {
              discountedPrice = (discountedPrice * (100 - percentOff)) / 100;
            }
            if (!!amountOff) {
              discountedPrice = discountedPrice - amountOff;
            }

            item.discountedPrice = discountedPrice;
          } else {
            // reset discounted price for current cart item
            item.discountedPrice = null;
          }
        }
      }

      if (!coupon.applies_to) {
        // We know the coupon applies to the whole cart
        const percentOff = coupon.percent_off;
        const amountOff = coupon.amount_off / 100;
        if (!!percentOff) {
          // set the global percent discount
          newCart.discountAmount = null;
          newCart.discountPercent = percentOff;
        }
        if (!!amountOff) {
          // set the global amount discount
          newCart.discountAmount = amountOff;
          newCart.discountPercent = null;
        }
      }
    }

    // COMPUTE TOTALS
    let _subtotal = 0;
    let _discount = 0;
    for (const item of newCart.contents.items) {
      _subtotal += item.price;
      if (item.discountedPrice !== null) {
        _discount += item.price - item.discountedPrice;
      }
    }
    //if (!newCart.coupon && _subtotal > 30) _subtotal -= 30;

    newCart.subtotal = _subtotal;
    if (_discount) {
      newCart.discountAmount = _discount;
      newCart.discountPercent = newCart.coupon.coupon.percent_off;
    }

    if (newCart.discountAmount === null && newCart.discountPercent === null) {
      newCart.total = _subtotal;
    }
    if (newCart.discountAmount) {
      newCart.total = _subtotal - newCart.discountAmount;
    }
    if (newCart.discountPercent) {
      newCart.total = (_subtotal * (100 - newCart.discountPercent)) / 100;
    }

    if (newCart.total < 0) {
      newCart.total = 0;
    }

    // Shipping fees are exempted when using a non-restricted 100% off coupon
    const isShippingExemptedFromCoupon =
      newCart.coupon && !newCart.coupon.coupon.applies_to && newCart.coupon.coupon.percent_off === 100;
    const shippingProductIsExempted = newCart?.coupon?.coupon.applies_to?.products.includes(
      process.env.GATSBY_STRIPE_PROD_SHIPPING
    );

    newCart.freeShipping =
      newCart.total >= 45 || newCart.total === 0 || isShippingExemptedFromCoupon || shippingProductIsExempted;
    if (!newCart.freeShipping) {
      newCart.total += newCart.definition.shippingFees;
    }

    setStoredCart(newCart);
  }

  return [storedCart, updateCart];
}
