import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import CartContext, { products } from '../../context/CartContext';
import styles from './cart-item.module.scss';
import CartPopupOptions from './cart-popup-options';

/**
 * Display a single BeMum product
 * in the cart
 * @param {Object} props - Component props
 * @param {import('../../context/CartContext').Product} props.item - A cart item
 * @param {boolean} props.checked - Checked attribute for the <input>
 */
function CartItem({ item, checked, isForCheckoutView }) {
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };
  const { cart, setCart } = useContext(CartContext);
  const data = useStaticQuery(graphql`
    query CartItemQuery {
      pagePanierYaml {
        essentielPack {
          publicURL
        }
        coaching {
          publicURL
        }
        antiox {
          publicURL
        }
        equilibre {
          publicURL
        }
        arrow {
          publicURL
        }
        trash {
          publicURL
        }
        star {
          publicURL
        }
        sopk {
          publicURL
        }
      }
    }
  `);

  if (!item) return null;

  const images = data.pagePanierYaml;
  let src;
  if (item.id === 'essentiel') {
    src = images.essentielPack.publicURL;
  }
  if (item.id === products.ANTIOX) {
    src = images.antiox.publicURL;
  }
  if (item.id === products.EQUILIBRE) {
    src = images.equilibre.publicURL;
  }
  if (item.id === products.COACHING) {
    src = images.coaching.publicURL;
  }
  if (item.id === products.SOPK_1_MONTH) {
    src = images.sopk.publicURL;
  }
  if (item.id === products.SOPK_3_MONTHS) {
    src = images.sopk.publicURL;
  }
  if (item.id === products.SOPK_6_MONTHS) {
    src = images.sopk.publicURL;
  }

  function toggleChecked() {
    if (checked) {
      return setCart('remove', 'product', item.id);
    }

    return setCart('add', 'product', item.id);
  }

  if (isForCheckoutView) {
    if (item.id !== 'essentiel' && !checked) return null;
    return (
      <div className={styles.content__checkout_container}>
        <img className={styles.image__checkout_container} src={src} alt="product" height="55" />
        <div className={styles.item_detail__checkout_container}>
          <span>{item.label}</span>
          {item.id === 'essentiel' ? (
            // <div>
            //   {cart.coupon ? (
            //     <div>
            //       <span className={styles.strikethrough}>61.50€</span>
            //       31,50€
            //     </div>
            //   ) : (
            //     <div>61.50€</div>
            //   )}
            // </div>
            <div>61.50€</div>
          ) : (
            <span>{item.price}€</span>
          )}
        </div>
      </div>
    );
  }

  if (item.id === 'essentiel') {
    return (
      <div className={styles.wrapper}>
        <CartPopupOptions modalOpen={modalOpen} closeModal={closeModal} option={item.id} checked={checked} />
        {/** IMAGE */}
        <div className={styles.imageWrapper}>
          <img src={src} alt="produit" />
        </div>
        {/** DESCRIPTION */}
        <div className={styles.cart_description}>
          <div className={styles.label}>{item.label}</div>
          <div className={styles.description}>{item.description}</div>
          {!!item.longDescription && <div className={styles.description}>{item.longDescription}</div>}
          <div>
            <div className={styles.star}>
              <img src={images.star.publicURL} alt="star" />
              <div className={styles.star_content}>
                2 téléconsultations avec votre <br />
                <span className="Medium">coach Fertilité</span>
              </div>
            </div>
            <div className={styles.star}>
              <img src={images.star.publicURL} alt="star" />
              <div className={styles.star_content}>
                <span className="Medium">Un régime profertilité </span>disponible sur{' '}
                <br className="xs-hide sm-hide md-hide" />
                votre <span className="Medium">application BeMum®</span>. Plus des fiches outils{' '}
                <span className="xs-hide sm-hide md-hide"> avec des conseils personnalisés. </span>
              </div>
            </div>
            <div className={styles.star}>
              <img src={images.star.publicURL} alt="star" />
              <div className={styles.star_content}>
                2 compléments alimentaires
                <span className="Medium">“ Le duo fertilité “</span>
              </div>
            </div>
          </div>
          <div className={`${styles.know_more}`} onClick={() => setModalOpen(true)}>
            En savoir plus
          </div>
        </div>

        {/** PRICE ORANGE */}
        <div className={styles.price_wrapper}>
          <div>
            <span className={styles.price}>Prix</span>
            {/* {!cart.coupon && <span className={styles.discountgreen}>50% OFF</span>} */}
          </div>
          <div className={styles.strikethrough_wrapper}>
            {/* {cart.coupon ? (
              <>
                <span className={`${styles.price} ${styles.strikethrough}`}>61.50€</span>
                <span className={`${styles.price} ${styles.first_month_price}`}>
                  31.50€
                  <span className={styles.priceMounth}>
                    /le <span className="xs-hide sm-hide md-hide">premier</span>
                    <span className="lg-hide">1er</span> mois
                  </span>
                </span>
              </>
            ) : (
              <div className={styles.price}>61.50€</div>
            )} */}
            <div className={styles.price}>61.50€</div>
          </div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <CartPopupOptions modalOpen={modalOpen} closeModal={closeModal} option={item.id} checked={checked} />
      {/** IMAGE */}
      <div className={styles.imageWrapper}>
        <img src={src} alt="produit" />
      </div>
      {/** DESCRIPTION */}
      <div className={styles.cart_description}>
        <div className={styles.label}>{item.label}</div>
        <div className={styles.description}>{item.description}</div>
        {!!item.longDescription && <div className={styles.description}>{item.longDescription}</div>}

        <div className={`${styles.know_more}`} onClick={() => setModalOpen(true)}>
          En savoir plus
        </div>
      </div>

      {/** PRICE ORANGE */}
      <div className={styles.price_wrapper}>
        <div>
          <span className={styles.price}>Prix</span>
        </div>
        <div className={styles.strikethrough_wrapper}>
          <div>
            <span className={styles.price}>{item.price}€</span>
          </div>
        </div>

        {/** CHECKBOX */}
        <div>
          <button
            className={`${styles.checkbox} ${item.id === products.ANTIOX ? styles.checkboxDisabled : ''}`}
            onClick={toggleChecked}
          >
            {checked && <div className={styles.checkbox_inner} />}
          </button>
        </div>
      </div>
    </div>
  );
}

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CartItem;
