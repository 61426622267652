import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import ConversionLayout from '../components/conversion-layout';
import CartItem from '../components/panier/cart-item';
import SopkCartItem from '../components/panier/cart-item-sopk';
import CartPopupOptions from '../components/panier/cart-popup-options';
import CouponForm from '../components/panier/coupon-form';
import Process, { steps } from '../components/repeatable/process';
import SEO from '../components/seo';
import Reassurance from '../components/static/rea';
import CartContext, { products, programs } from '../context/CartContext';
import useFacebookConversionApi from '../hooks/useFacebookConversionApi';
import useLocalStorage from '../hooks/useLocalStorage';
import styles from '../modules/panier.module.scss';

/**
 * Component to display when the cart is empty
 */
function EmptyCart({ fertilityTestResults }) {
  return (
    <div className={`wrapper ${styles.emptyCartWrapper}`}>
      <div className={styles.ctaWrapper}>
        <h2 className={styles.ctaTitle}>Votre panier est vide !</h2>
        {!fertilityTestResults && (
          <>
            <p className={`${styles.ctaInfo} p0`}>Vous souhaitez savoir si le programme BeMum est fait pour vous ?</p>
            <Link className={`Btn Btn--Purple ${styles.ctaButton}`} to={`/test-fertilite/form/`}>
              Je fais le test fertilité
            </Link>
          </>
        )}
        {fertilityTestResults && (
          <>
            <p className="p0">Souhaitez-vous revoir le programme que nous vous avions conseillé ?</p>
            <Link className={`Btn Btn--Purple ${styles.ctaButton}`} to={`/resultats/programme-essentiel/`}>
              Voir les résultats de mon test
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

/**
 * Page component
 */
function Cart() {
  const { cart, setCart } = useContext(CartContext);
  const [hasMounted, setHasMounted] = React.useState(false);
  const location = useLocation();
  const [fertilityTestResults] = useLocalStorage('answers', null);
  const scoreObject = useLocalStorage('score', null);

  const [subscribedToMarketing, setSubscribedToMarketing] = React.useState(false);

  const queryParams = new URLSearchParams(location.search);
  const fromResults = queryParams.get('from') === 'results';
  const [displayedProducts, setDisplayedProducts] = useState([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const productsFromUrl = queryParams.getAll('product'); // Récupère tous les paramètres 'product'
    setDisplayedProducts(productsFromUrl);
  }, [location]);

  const shouldDisplayProduct = (productName) => {
    if (!fromResults) return displayedProducts.includes(productName);

    const scoreExists = scoreObject && scoreObject[0];

    return scoreExists ? scoreObject[0][productName] >= 20 : true;
  };
  /**
   * Set by google tag manager
   * Used by Facebook Business Manager to deduplicate events
   * @see https://www.facebook.com/business/help/823677331451951?id=1205376682832142
   */
  const [eventId] = useLocalStorage('eventId', null);
  useFacebookConversionApi(
    'PageView',
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.phone,
    fertilityTestResults?.firstname,
    eventId
  );
  useFacebookConversionApi(
    'AddToCart',
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.phone,
    fertilityTestResults?.firstname,
    eventId,
    cart.total
  );

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  const [nbOptions, setNbOptions] = useState(0);

  useEffect(() => {
    let newNbOptions = 0;
    const hasCoachingOption = cart.contents.items.find((item) => item.id === products.COACHING);
    const hasAntioxOption = cart.contents.items.find((item) => item.id === products.ANTIOX);
    const hasEquilibreOption = cart.contents.items.find((item) => item.id === products.EQUILIBRE);

    if (hasCoachingOption) newNbOptions += 1;
    if (hasAntioxOption) newNbOptions += 1;
    if (hasEquilibreOption) newNbOptions += 1;

    setNbOptions(newNbOptions);
  }, [cart]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    async function reloadCart() {
      if (params.coaching || params.antiox || params.equilibre) setCart('add', 'program', programs.ESSENTIEL);
      if (params.coaching && params.coaching === 'true') setCart('add', 'product', products.COACHING);
      if (params.antiox && params.antiox === 'true') setCart('add', 'product', products.ANTIOX);
      if (params.equilibre && params.equilibre === 'true') setCart('add', 'product', products.EQUILIBRE);
    }
    reloadCart();
  }, [location, setCart]);

  /**
   * Opt in to Abandoned Cart marketing
   * @see https://modern-javascript.fr/comment-utiliser-une-async-function-dans-un-hook-useeffect-avec-react/
   **/
  useEffect(() => {
    if (subscribedToMarketing) return;
    async function subscribeToAbandonedCartMarketing() {
      if (!fertilityTestResults) {
        return;
      }

      const tags = ['bemum_v2'];
      /** cf issue fablife/bemum#1336 audience segmentation */
      if (fertilityTestResults.age >= 35) tags.push('+35 ans');
      if (fertilityTestResults.infertility_diagnosis.includes('sopk')) tags.push('sopk');
      if (fertilityTestResults.infertility_diagnosis.includes('endometriose')) tags.push('endo');
      if (fertilityTestResults.baby_journey === 'j-ai-un-diagnostic-d-infertilite') tags.push('suivi médical');

      const endpoint = `${process.env.GATSBY_API}/mailchimp/audience/abandoned-cart/members`;
      setSubscribedToMarketing(true);
      await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: fertilityTestResults.email,
          mergeFields: {
            FNAME: fertilityTestResults.firstname,
            BM_PG_NAME: programs.ESSENTIEL,
            BM_PG_LINK: `${location.origin}/panier/`,
            W_COACHING: scoreObject.length && scoreObject[0].coaching >= 20 ? 'true' : 'false',
            W_ANTIOX: scoreObject.length && scoreObject[0].antiox >= 20 ? 'true' : 'false',
            W_EQUILIBR: scoreObject.length && scoreObject[0].poids >= 20 ? 'true' : 'false',
          },
          tags: tags,
        }),
      });
    }
    // Subscribe only if cart was seen for first time
    const params = queryString.parse(location.search);
    if (!params.coaching && !params.antiox & !params.equilibre) subscribeToAbandonedCartMarketing();
  }, [fertilityTestResults, scoreObject, location]);

  /**
   * This is to avoid rehydration issues
   * Inspired from https://www.joshwcomeau.com/react/the-perils-of-rehydration/#the-solution
   */
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  //temporary for header
  const isSOPKProgram = ['pack-sopk-6-months', 'pack-sopk-3-months', 'pack-sopk-1-month'].includes(
    cart.contents.program
  );
  const productKeys = ['antiox', 'equilibre', 'coaching'];
  return (
    // <ConversionLayout banner={!cart.coupon}>
    <div>
      <div className={styles.wrapper}>
        <header>
          {isSOPKProgram ? <Link to="/sopk" className={styles.logo} /> : <Link to="/" className={styles.logo} />}
        </header>
        <main style={{ maxWidth: '100vw', overflow: 'hidden' }}>{/* Contenu principal ici */}</main>
      </div>

      <SEO title="Panier" canonical={`https://www.bemum.co/panier/`} />
      <CartPopupOptions modalOpen={modalOpen} closeModal={closeModal} option={programs.ESSENTIEL} />

      <button className={styles.backButton} onClick={() => navigate(-1)}>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5 4L5.5 12.5L15.5 21" stroke="black" />
          <circle cx="12.5" cy="12.5" r="12" stroke="black" />
        </svg>
      </button>

      {cart.contents.items.length === 0 && !cart.contents.program && (
        <EmptyCart fertilityTestResults={fertilityTestResults} />
      )}

      {(cart.contents.items.length || cart.contents.program) && (
        <div className={styles.main}>
          {/** Cart Header */}
          <div className={styles.header}>
            <Process currentStep={steps.CART} />
          </div>

          {/** Cart Content */}
          <div>
            <div className={styles.cart}>
              <div>
                <h1 className={`Center ${styles.cart_title}`}>Panier</h1>
                <div className={styles.products}>
                  {cart?.contents.program === 'essentiel' ? (
                    <>
                      <CartItem item={cart.definition.availablePrograms['essentiel']} />

                      {shouldDisplayProduct('antiox') && (
                        <CartItem
                          item={cart.definition.availableProducts['antiox']}
                          checked={!!cart.contents.items.find((item) => item.id === products.ANTIOX)}
                        />
                      )}
                      {(shouldDisplayProduct('equilibre') || shouldDisplayProduct('poids')) && (
                        <CartItem
                          item={cart.definition.availableProducts['equilibre']}
                          checked={!!cart.contents.items.find((item) => item.id === products.EQUILIBRE)}
                        />
                      )}
                      {shouldDisplayProduct('coaching') && (
                        <CartItem
                          item={cart.definition.availableProducts['coaching']}
                          checked={!!cart.contents.items.find((item) => item.id === products.COACHING)}
                        />
                      )}
                    </>
                  ) : (
                    <SopkCartItem item={cart.contents.items} />
                  )}
                </div>
              </div>

              <div>
                <div className={`Center t2 ${styles.cart_title}`}>Total panier</div>
                <div className={styles.cart_recap}>
                  {cart?.contents.program === 'pack-sopk-3-months'
                    ? 'Votre programme se renouvelle automatiquement tous les 3 mois'
                    : cart?.contents.program === 'pack-sopk-6-months'
                    ? ''
                    : 'Votre programme se renouvelle automatiquement tous les 30 jours'}

                  <div className={`Medium ${styles.cart_recap_content}`}>
                    {cart?.contents.program === 'pack-sopk-1-month'
                      ? 'Pack SOPK 1 mois'
                      : cart?.contents.program === 'pack-sopk-3-months'
                      ? 'Pack SOPK 3 mois'
                      : cart?.contents.program === 'pack-sopk-6-months'
                      ? 'Pack SOPK 6 mois'
                      : 'Essentiel'}
                    {nbOptions === 1 && <span>+ {nbOptions} option </span>}
                    {nbOptions > 1 && <span>+ {nbOptions} options </span>}
                  </div>

                  <div className={`mt2 mb3 ${styles.cart_promo}`}>
                    <CouponForm />
                  </div>

                  <div>
                    <div className={styles.total}>
                      <span>Sous-total :</span>
                      <span>{cart.subtotal}€</span>
                    </div>
                    <div className={styles.total}>
                      <span>Livraison&nbsp;:</span>
                      <span>{cart.freeShipping ? 'Offert' : '6.5€'}</span>
                    </div>
                    {cart && (cart?.discountAmount || cart?.discountPercent) && (
                      <div className={styles.totalLine}>
                        <span>Code promo&nbsp;:</span>
                        <span>
                          {cart.coupon.coupon.amount_off ? `-${cart.discountAmount}€` : `-${cart.discountPercent}%`}
                        </span>
                      </div>
                    )}
                    <div className={styles.total}>
                      <span>Total :</span>
                      <span>{cart.total}€</span>
                    </div>
                  </div>

                  <div className={styles.action}>
                    <Link className={`Btn Btn--Black ${styles.btn}`} to={'/checkout/'}>
                      Continuer
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Reassurance />
    </div>
    // </ConversionLayout>
  );
}

export default Cart;
