import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './cart-item.module.scss';

function SopkCartItem({ item }) {

    const data = useStaticQuery(graphql`
      query SopkCartItemQuery {
        pagePanierYaml {
          arrow {
            publicURL
          }
          trash {
            publicURL
          }
          star {
            publicURL
          }
          sopk {
            publicURL
          }
        }
      }
    `);
    const images = data.pagePanierYaml;

    return (

        <div className={styles.wrapper}>
            {/** IMAGE */}
            <div className={styles.imageWrapper}>
                <img src={images.sopk.publicURL} alt="produit" />
            </div>
            {/** DESCRIPTION */}
            <div className={styles.cart_description}>
                <div className={styles.label}>{item[0].label}</div>
                <div className={styles.description}>{item[0].description}</div>
                {!!item.longDescription && <div className={styles.description}>{item.longDescription}</div>}
                <div>
                    <div className={styles.star}>
                        <img src={images.star.publicURL} alt="star" />
                        <div className={styles.star_content}>
                            UN COACHING AVEC UNE DIÉTÉTICIENNE
                        </div>
                    </div>
                    <div className={styles.star}>
                        <img src={images.star.publicURL} alt="star" />
                        <div className={styles.star_content}>
                            DES RECETTES PROFERTILITÉ
                        </div>
                    </div>
                    <div className={styles.star}>
                        <img src={images.star.publicURL} alt="star" />
                        <div className={styles.star_content}>
                            DES COMPLÉMENTS ALIMENTAIRES FABRIQUÉS EN FRANCE
                        </div>
                    </div>
                </div>

            </div>

            {/** PRICE ORANGE */}
            <div className={styles.price_wrapper}>
                <div>
                    <span className={styles.price}>Prix</span>

                </div>
                <div className={styles.strikethrough_wrapper}>
                    <div className={styles.price}>{item[0].price}€</div>
                </div>
                <div></div>
            </div>
        </div>
    );
}

SopkCartItem.propTypes = {
    item: PropTypes.object.isRequired,
};

export default SopkCartItem;