import React from 'react';
import { useCart } from '../hooks/useCart';

/** BeMum products */
export const products = Object.freeze({
  ESSENTIEL_COACHING: 'essentielCoaching',
  ESSENTIEL_SUPPLEMENTS: 'essentielSupplements',
  ESSENTIEL_MENUS: 'essentielMenus',
  ANTIOX: 'antiox',
  EQUILIBRE: 'equilibre',
  COACHING: 'coaching',
  SOPK_1_MONTH: 'sopk-1-month',
  SOPK_3_MONTHS: 'sopk-3-months',
  SOPK_6_MONTHS: 'sopk-6-months'
});

/** BeMum programs */
export const programs = Object.freeze({
  ESSENTIEL: 'essentiel',
  PACK_SOPK_1_MONTH: 'pack-sopk-1-month',
  PACK_SOPK_3_MONTHS: 'pack-sopk-3-months',
  PACK_SOPK_6_MONTHS: 'pack-sopk-6-months'
});

/**
 * Version of the cart.
 * Bump this number when publishing a backward incompatible change to the Cart data structure.
 * useCart hook will destroy any cart using a previous version than this.
 *
 * @typedef CartVersion
 * @type {number}
 **/
export const CURRENT_CART_VERSION = 15;

/**
 * Data structure of a BeMum shopping cart
 * @typedef Cart
 * @type {object}
 * @property {CartContents} contents
 * @property {?StripeCoupon} coupon
 * @property {Definition} definition
 * @property {?number} discountAmount - Amount discounted from the cart subtotal.
 * @property {?number} discountPercent - Percent discounted from the cart subtotal.
 * @property {number} subtotal - Sum of each cart items price
 * @property {boolean} freeShipping - Shipping fees or not
 * @property {number} total - Sum of each cart items price, minus a possible discount
 * @property {CartVersion} version
 */

/** Contents of the cart, ie. it's what has been added by the customer.
 * @typedef CartContents
 * @property {Product[]} items - Products that will actually be bought
 * @property {?ProgramName} program - Name of the latest program added to the cart
 */

/**
 * Static, exhaustive definition of what BeMum sells. It is NOT supposed to be mutated at runtime.
 * @typedef Definition
 * @type {object}
 * @property {Object.<string, Product>} availableProducts - Products to be sold
 * @property {Object.<string, Program>} availablePrograms - Programs to be sold
 * @property {number} shippingFees - Price of the shipping
 */

/**
 * Data structure representing a BeMum product
 * @typedef Product
 * @type {object}
 * @property {string} description - Description of the product
 * @property {string} longDescription - Long description of the product
 * @property {?boolean} isLongDescriptionHideable - Should the long description be always shown, or should it be hideable
 * @property {?number} discountedPrice - Price of the product after applying a discount
 * @property {ProductName} id - Identifier of the product
 * @property {string} label - Label (name) of the product
 * @property {number} price - Initial price of the product, without any discount applied on it
 * @property {?string} refund - User-facing info about health insurance refunds
 * @property {string[]} stripe_product_id - The product identifier in Stripe
 */

/**
 * Data structure representing a BeMum program,
 * which is nothing more than a pack of BeMum products
 * @typedef Program
 * @type {object}
 * @property label - Label (Name) of the program
 * @property {ProductName[]} - List of products included in the program
 */

/**
 * Possible names for a BeMum program
 * @typedef ProgramName
 * @type {'essentiel'}
 */

/**
 * Possible names for a BeMum product
 * @typedef ProductName
 * @type {'essentielCoaching'|'essentielSupplements'|'essentielMenus'|'antiox'|'equilibre'|'coaching'}
 */

/**
 * Object definition here {@link https://stripe.com/docs/api/promotion_codes/object Stripe docs}
 * @typedef StripeCoupon
 * @type {object}
 */

/** @type {Cart} */
export const defaultCart = {
  contents: {
    items: [],
    program: null,
  },
  coupon: null,
  definition: {
    availableProducts: {
      [products.ESSENTIEL_SUPPLEMENTS]: {
        description: 'Boosters de fertilité',
        longDescription:
          'Compléments alimentaires associant <span style="white-space: nowrap">oméga-3</span>, vitamines et minéraux (qualité EPAX©)',
        isLongDescriptionHideable: true,
        discountedPrice: null,
        price: 31.50,
        id: products.ESSENTIEL_SUPPLEMENTS,
        label: 'Les compléments Essentiels',
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_ESSENTIAL_SUPPLEMENTS,
      },
      [products.ESSENTIEL_COACHING]: {
        description: 'Nutritionniste spécialisée en fertilité',
        longDescription: 'Remboursable par votre mutuelle',
        isLongDescriptionHideable: false,
        discountedPrice: null,
        price: 30,
        id: products.ESSENTIEL_COACHING,
        label: '1 téléconsultation',
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_ESSENTIAL_COACHING,
      },
      [products.ESSENTIEL_MENUS]: {
        description: 'Menus sur-mesure',
        longDescription:
          'Personnalisés en fonction de vos préférences et de vos besoins, pour améliorer votre fertilité.',
        isLongDescriptionHideable: true,
        discountedPrice: null,
        price: 0,
        id: products.ESSENTIEL_MENUS,
        label: 'Régime profertilité',
      },
      [products.ANTIOX]: {
        description: 'Protection contre le stress oxydatif',
        discountedPrice: null,
        id: products.ANTIOX,
        label: 'Spécificité + 35 ans',
        price: 19.80,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_35YO,
      },
      [products.EQUILIBRE]: {
        description: 'Gestion du poids et du SOPK',
        discountedPrice: null,
        id: products.EQUILIBRE,
        label: 'Spécificité poids / SOPK',
        price: 16.50,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_WEIGHT,
      },
      [products.COACHING]: {
        description: "2 rdv / mois au lieu d'un seul",
        longDescription: 'Remboursable par votre mutuelle',
        isLongDescriptionHideable: false,
        discountedPrice: null,
        id: products.COACHING,
        label: 'Coaching +',
        price: 30,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_COACHING,
      },

      [products.SOPK_1_MONTH]: {
        description: "1 mois",
        longDescription: 'Remboursable par votre mutuelle',
        isLongDescriptionHideable: false,
        discountedPrice: null,
        id: products.SOPK_1_MONTH,
        label: 'Pack SOPK',
        price: 78,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_SOPK_1_MONTH,
      },
      [products.SOPK_3_MONTHS]: {
        description: "3 mois",
        longDescription: 'Remboursable par votre mutuelle',
        isLongDescriptionHideable: false,
        discountedPrice: null,
        id: products.SOPK_3_MONTHS,
        label: 'Pack SOPK',
        price: 199,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_SOPK_3_MONTHS,
      },
      [products.SOPK_6_MONTHS]: {
        description: "6 mois",
        longDescription: 'Remboursable par votre mutuelle',
        isLongDescriptionHideable: false,
        discountedPrice: null,
        id: products.SOPK_6_MONTHS,
        label: 'Pack SOPK',
        price: 375,
        stripe_product_id: process.env.GATSBY_STRIPE_PROD_SOPK_6_MONTHS,
      },
    },
    availablePrograms: {
      [programs.ESSENTIEL]: {
        id: programs.ESSENTIEL,
        label: 'Les Essentiels',
        products: [products.ESSENTIEL_MENUS, products.ESSENTIEL_SUPPLEMENTS, products.ESSENTIEL_COACHING],
      },
      [programs.PACK_SOPK_1_MONTH]: {
        id: programs.PACK_SOPK_1_MONTH,
        label: 'SOPK 1 MOIS',
        products: [products.SOPK_1_MONTH],
      },
      [programs.PACK_SOPK_3_MONTHS]: {
        id: programs.PACK_SOPK_3_MONTHS,
        label: 'SOPK 3 MOIS',
        products: [products.SOPK_3_MONTHS],
      },
      [programs.PACK_SOPK_6_MONTHS]: {
        id: programs.PACK_SOPK_6_MONTHS,
        label: 'SOPK 6 MOIS',
        products: [products.SOPK_6_MONTHS],
      },
    },
    shippingFees: 6.5,
  },
  discountAmount: null,
  discountPercent: null,
  subtotal: 0,
  freeShipping: false,
  total: 0,
  version: CURRENT_CART_VERSION,
};

const CartContext = React.createContext({ cart: defaultCart, setCart: () => { } });

/** Provide a single source of truth for BeMum shopping cart contents. */
function CartProvider({ children }) {
  const [cart, setCart] = useCart();

  return <CartContext.Provider value={{ cart, setCart }}>{children}</CartContext.Provider>;
}

export default CartContext;
export { CartProvider };
