import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Footer from './footer';
import Banner from './banner';
import styles from './module/conversion-layout.module.scss';

/**
 * This layout is used in the BeMum funnel
 * It has a minimal Header
 */
const ConversionLayout = ({ children, banner }) => {
  return (
    <div className={styles.wrapper}>
      <header>
          <Link to="/" className={styles.logo} />
      </header>

      {/* {banner && <Banner funnel />} */}

      <main style={{ maxWidth: '100vw', overflow: 'hidden' }}>{children}</main>

      <Footer />
    </div>
  );
};

ConversionLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ConversionLayout;
