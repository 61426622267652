import { useLocation } from '@reach/router';
import axios from 'axios';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { TrustPilotBox } from 'gatsby-plugin-trustpilot-trustbox';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import ConversionLayout from '../components/conversion-layout';
import Essential from '../components/resultats/essential';
import Faq from '../components/resultats/faq';
import {
  getProfileDescription,
  getFactorsString,
  getTagsListForFactors,
  getOptionName,
  getDiagnosis,
  getPercent,
} from '../components/resultats/helpers';
import SEO from '../components/seo';
import Partners from '../components/static/partners';
import useFacebookConversionApi from '../hooks/useFacebookConversionApi';
import useLocalStorage from '../hooks/useLocalStorage';
import styles from '../modules/resultats.module.scss';
import { calculateScore } from '../pages/test-fertilite/form';
import FertilityTestModal from '../components/fertility-test/modal';
import Team from '../components/static/team';
import { GatsbyImage } from 'gatsby-plugin-image';
import ProgressBar from '../components/utils/progress-bar';

function Results() {
  const { pregnant_women } = useStaticQuery(graphql`
    query ResultsPageQuery {
      pregnant_women: file(relativePath: { eq: "resultats/bemum-resultat-fertilite.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const location = useLocation();
  const essentialRef = useRef(null);
  const [fertilityTestResults, setFertilityTestResults] = useLocalStorage('answers', null);
  const [resultsFetched, setResultsFetched] = useState(false);
  const [scoreObject, setScoreObject] = useLocalStorage('score', null);
  const firstname =
    fertilityTestResults && fertilityTestResults['firstname'] ? fertilityTestResults['firstname'] : 'Bonjour 👋';

  /**
   * Set by google tag manager
   * Used by Facebook Business Manager to deduplicate events
   * @see https://www.facebook.com/business/help/823677331451951?id=1205376682832142
   */
  const [eventId] = useLocalStorage('eventId', null);
  useFacebookConversionApi(
    'PageView',
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId
  );
  const [fbEventName, setFbEventName] = useState('Lead');
  useFacebookConversionApi(
    fbEventName,
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId
  );
  const [modalOpen, setModalOpen] = React.useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (essentialRef && essentialRef.current && location.href.includes('anchor-essential')) {
      essentialRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const params = queryString.parse(location.search);
    const email = params.email;

    async function retrieveTestResults() {
      if (!email) {
        setModalOpen(true);
        return;
      }
      if (resultsFetched) return;
      try {
        const { data } = await axios.get(`${process.env.GATSBY_API}/fertility-test/email?email=${email}`);
        setResultsFetched(true);
        const { id, ...answers } = data;
        answers.weight = answers.weight.toString();
        answers.height = answers.height.toString();
        answers.age = answers.age.toString();
        answers.stress = answers.stress.toString();
        answers.sleep = answers.sleep.toString();
        answers.food_supplements_content = answers.food_supplements_content ? answers.food_supplements_content : [];
        answers.former_smoker = answers.former_smoker ? answers.former_smoker : '';
        answers.smoking_partner = answers.smoking_partner ? answers.smoking_partner : '';
        setFertilityTestResults(answers);
        const updatedScore = calculateScore(answers);
        setScoreObject(updatedScore);
      } catch (error) {
        const data = error.response.data;
        if (data.statusCode === 400) {
          alertSSR('Erreur: email invalide');
          navigateSSR('/');
        }
        if (data.statusCode === 404) {
          alertSSR(`Erreur: cette adresse email n'a pas de test fertilité associé.`);
          navigateSSR('/');
        }
      }
    }
    if (!fertilityTestResults || email) retrieveTestResults();
  }, [fertilityTestResults, location, setFertilityTestResults, setScoreObject, resultsFetched, setResultsFetched]);
  const [tagList, setTagList] = useState([]);
  const [optionName, setOptionName] = useState([]);
  const [diagnosis, setDiagnosis] = useState(null);
  const [percent, setPercent] = useState(null);
  const [content1, setContent1] = useState(null);
  const [content2, setContent2] = useState(null);

  function handleContact() {
    setFbEventName('Contact');
    window.open('https://calendly.com/bemum/15min', '_blank').focus();
  }

  useEffect(() => {
    if (!scoreObject) return;
    const tagList = getTagsListForFactors(fertilityTestResults);
    setTagList(tagList);

    const optionName = getOptionName(scoreObject);
    setOptionName(optionName);
    const diagnosis = getDiagnosis(scoreObject, fertilityTestResults);
    setDiagnosis(diagnosis);
    const percent = getPercent(optionName);
    setPercent(percent);

    const { content1, content2 } = getProfileDescription(fertilityTestResults);
    setContent1(content1);
    setContent2(content2);
  }, [scoreObject, fertilityTestResults]);

  useEffect(() => {
    async function saveTestResults() {
      if (!fertilityTestResults) {
        return;
      }
      const endpoint = `${process.env.GATSBY_TEST_RESULT_ENDPOINT}/fertility-test-result`;
      await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: fertilityTestResults.email,
          firstname: fertilityTestResults.firstname,
          programLink: `${location.origin}/monprogramme/essentiel/`,
        }),
      });
    }
    const params = queryString.parse(location.search);
    // Save results only if test has just been taken
    if (!params.email && location.pathname.includes('resultats')) saveTestResults();
  }, [fertilityTestResults, location]);

  /** @see https://modern-javascript.fr/comment-utiliser-une-async-function-dans-un-hook-useeffect-avec-react/ */
  useEffect(() => {
    /** Opt in to Fertility Test marketing */
    async function subscribeToMarketing() {
      if (!fertilityTestResults || !tagList.length) {
        return;
      }

      const factors = getFactorsString(tagList);

      /** cf issue fablife/bemum#1336 audience segmentation */
      const tags = ['bemum_v2'];
      if (fertilityTestResults.age >= 35) tags.push('+35 ans');
      if (fertilityTestResults.infertility_diagnosis.includes('sopk')) tags.push('sopk');
      if (fertilityTestResults.infertility_diagnosis.includes('endometriose')) tags.push('endo');
      if (fertilityTestResults.baby_journey === 'j-ai-un-diagnostic-d-infertilite') tags.push('suivi médical');

      const endpoint = `${process.env.GATSBY_API}/mailchimp/audience/fertility-test-v2/members`;
      await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: fertilityTestResults.email,
          mergeFields: {
            FNAME: fertilityTestResults.firstname,
            BM_RE_LINK: location.origin + '/resultats/programme-essentiel/',
            W_COACHING: scoreObject.coaching >= 20 ? 'true' : 'false',
            W_ANTIOX: scoreObject.antiox >= 20 ? 'true' : 'false',
            W_EQUILIBR: scoreObject.poids >= 20 ? 'true' : 'false',
            AGE_35: fertilityTestResults.age >= 35 ? 'true' : 'false',
            SOPK: fertilityTestResults.infertility_diagnosis.includes('sopk') ? 'true' : 'false',
          },
          tags: tags,
        }),
      });
    }
    // Subscribe only if test has just been taken
    const params = queryString.parse(location.search);
    if (!params.email && location.pathname.includes('resultats')) subscribeToMarketing();
  }, [fertilityTestResults, location, tagList, scoreObject]);

  return (
    <ConversionLayout>
      <SEO title="Résultats" noindex />
      <div className={`truspilot_box`}>
        <TrustPilotBox locale="fr-FR" defaultRevueURL="https://fr.trustpilot.com/review/bemum.co" minReviewCount="10" />
      </div>

      <FertilityTestModal modalOpen={modalOpen} closeModal={closeModal} />

      <div className={styles.profile_container}>
        {/** Fertility Profile */}
        {optionName && diagnosis && (
          <section>
            <div>
              <div className={styles.goals_image__desktop}>
                <GatsbyImage
                  image={pregnant_women.childImageSharp.gatsbyImageData}
                  alt="femme enceinte"
                  className={styles.goals_image__mobile}
                />
                <div className={styles.profile}>
                  <div className={styles.profile_content}>
                    <h1 style={{ whiteSpace: 'nowrap', lineHeight: '27px' }}>
                      <span className={`${styles.profile_content__title} Medium`}>Votre profil fertilité est : </span>
                      <span className={`${styles.profile_optionName}`}>
                        {optionName.name}
                        <span>
                          <sup className={styles.profile_optionName_plus}>{optionName.plus}</sup>
                        </span>
                      </span>
                    </h1>

                    <div className={styles.progress_wrapper}>
                      <div>Risque de déséquilibre hormonal</div>
                      <div
                        className={`${styles.progress_text} ${
                          scoreObject.poids >= 20 ? styles.red_text : styles.green_text
                        }`}
                        dangerouslySetInnerHTML={{ __html: diagnosis.hormonalDisorder }}
                      />
                      <ProgressBar score={scoreObject.poids} progress={diagnosis.hormonalDisorderPercent} />

                      <div>Risque de stress oxydatif</div>
                      <div
                        className={`${styles.progress_text} ${
                          scoreObject.antiox >= 20 ? styles.red_text : styles.green_text
                        }`}
                        dangerouslySetInnerHTML={{ __html: diagnosis.oxydativeStress }}
                      />
                      <ProgressBar score={scoreObject.antiox} progress={diagnosis.oxydativeStressPercent} />

                      <div>Risque d’une mauvaise hygiène de vie</div>
                      <div
                        className={`${styles.progress_text} ${
                          scoreObject.coaching >= 20 ? styles.red_text : styles.green_text
                        }`}
                        dangerouslySetInnerHTML={{ __html: diagnosis.lifeHigiene }}
                      />
                      <ProgressBar score={scoreObject.coaching} progress={diagnosis.lifeHigienePercent} />
                    </div>
                  </div>
                  <div>
                    <div className={styles.profile_description} dangerouslySetInnerHTML={{ __html: content1 }} />
                    <div className={styles.profile_description} dangerouslySetInnerHTML={{ __html: content2 }} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>

      <div ref={essentialRef}>
        <Essential firstname={firstname} optionName={optionName} scoreObject={scoreObject} />
      </div>

      <div className={`${styles.calendly} Center`}>
        {/* Video explicative youtube */}
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/SDQLqLcs3rg?rel=0&autoplay=0&mute=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer;?autoplay; clipboard-write; encrypted-media; gyrosco"
          id="audio"
          allowfullscreen
        ></iframe>
        <div className={styles.calendly_title}>Vous avez encore des questions ?</div>

        <div className={styles.calendly_subtitle}>Prenez rendez-vous avec une de nos coachs fertilité</div>
        <button className={`Btn ${styles.calendly_button}`} onClick={handleContact}>
          PRENDRE UN RENDEZ-VOUS
        </button>
      </div>

      {optionName && diagnosis && (
        <section>
          <div className={styles.result_description}>
            <div className={styles.result_description_container}>
              <div className={styles.result_description_left_side}>
                <h2>
                  <span className={`${styles.result_description_title} Center`}>
                    {optionName.name}&nbsp;<sup>{optionName.plus}</sup>
                  </span>
                </h2>

                <div className={`${styles.result_child_description}`}>
                  <span>
                    Vous êtes <br className="xs-hide sm-hide md-hide" /> comme{' '}
                    <br className="xs-hide sm-hide md-hide" />
                    {percent}&nbsp;% <br className="xs-hide sm-hide md-hide" /> des abonnées{' '}
                    <br className="xs-hide sm-hide md-hide" /> BeMum.
                  </span>
                </div>
              </div>

              <div className={styles.result_description_right_side}>
                {!diagnosis.boostFertility ? (
                  <ul>
                    <li className={`${styles.contents_items} p0`}>
                      <div className={styles.contents_single_item}>
                        <span className={styles.contents_single_item_title}>Stress oxydatif </span>{' '}
                        <div className={styles.line} />
                        <div dangerouslySetInnerHTML={{ __html: diagnosis.oxydativeStressDetails }} />
                      </div>
                    </li>
                    <li className={`${styles.contents_items} p0`}>
                      <div className={styles.contents_single_item}>
                        <span className={styles.contents_single_item_title}>Dérèglement hormonal </span>
                        <div className={styles.line} />
                        <div dangerouslySetInnerHTML={{ __html: diagnosis.hormonalDisorderDetails }} />
                      </div>
                    </li>
                    <li className={`${styles.contents_items} p0`}>
                      <div className={styles.contents_single_item}>
                        <span className={styles.contents_single_item_title}> Hygiène de vie </span>
                        <div className={styles.line} />
                        <div dangerouslySetInnerHTML={{ __html: diagnosis.lifeHigieneDetails }} />
                      </div>
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li className={`${styles.contents_items} p0`}>
                      <div className={styles.contents_single_item}>
                        <span className={styles.contents_single_item_title}> Boost fertilité </span>
                        <div className={styles.line} />
                        <div dangerouslySetInnerHTML={{ __html: diagnosis.boostFertility }} />
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </section>
      )}

      <Partners />
      <Team />
      <Faq />
    </ConversionLayout>
  );
}

/** Native alert is not available during SSR */
function alertSSR(message) {
  if (typeof alert === 'undefined') {
    return;
  }
  alert(message);
}

function navigateSSR(to, options) {
  if (typeof window === 'undefined') {
    return;
  }
  navigate(to, options);
}

export default Results;
